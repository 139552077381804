import { composeValidators } from 'revalidate';
import { isObject, isString, isRequiredString } from 'Utils/validators';
import translationValidator from './translationValidator';

const imprintConfigValidator = (defaultLanguage) => ({
  company: isString('company'),
  address: isString('address'),
  phone: isString('phone'),
  email: isRequiredString('email'),
  customHtmlSourcePath: composeValidators(
    isObject(''),
    translationValidator(defaultLanguage)
  )('customHtmlSourcePath')
});

export default imprintConfigValidator;
