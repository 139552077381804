import React, { useCallback, useContext, useState, useEffect } from 'react';
import {
  Route,
  useHistory,
  useParams
} from 'react-router-dom';
import api from 'Api';
import useConfigSettings from 'Hooks/useConfigSettings';
import useHandleError from 'Utils/handleError';
import LoadingSpinner from 'Components/shared/LoadingSpinner';
import Text from 'Components/shared/Text';
import { AuthContext } from 'States/auth/authState';
import { ConfigContext } from 'States/config/configState';
import { LangContext } from 'States/lang/langState';

import RedemptionDetailsForm from './RedemptionDetailsForm';
import { KycContext } from 'States/kyc/kycState';
import { ROUTES } from 'Router/Routes';

const RedeemDetails = () => {
  const history = useHistory();
  const {
    id: currentMethodId
  } = useParams();
  const {
    setConfig: setCurrentlyLoadedConfig,
    config
  } = useContext(ConfigContext);
  const { isKycEnabled, currentKycTier } = useContext(KycContext);
  const { isAdmin } = useContext(AuthContext);
  const { lang } = useContext(LangContext);
  const {
    data: { RestrictedMode, defaultLanguageCode }
  } = useConfigSettings.query({
    select: useCallback(({ RestrictedMode, Languages }) => {
      const language = Languages.find(({ IsDefault }) => IsDefault);
      return ({
        RestrictedMode,
        defaultLanguageCode: language?.Code
      });
    }, [])
  });

  const [redemptionMethod, setRedemptionMethod] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const handleError = useHandleError();

  const getConfigTranslation = (translations) => {
    if (!translations) {
      return;
    }
    return translations[lang] || translations[defaultLanguageCode] ||
      translations["en"] || Object.values(translations).find(translation => !!translation);
  };

  useEffect(() => {
    const getRedemptionMethodFromConfig = async () => {
      try {
        const configBE = await api.Config.getFullConfig();
        setCurrentlyLoadedConfig(configBE);
        let redemptionMethod = configBE.redeemSettings.redemptionMethods.find(
          (redemptionMethod) => {
            return (
              redemptionMethod.id === currentMethodId &&
              redemptionMethod.enabled
            );
          }
        );
        if (!redemptionMethod) {
          history.replace(ROUTES.redemption);
        } else {
          const {
            Name,
            UnitOfMeasureCode,
            CurrencyCode,
            DecimalPrecision,
            Translations
          } = await api.Assets.getAsset(redemptionMethod.userPays.uniqueAssetId);
          redemptionMethod = {
            ...redemptionMethod,
            userPays: {
              ...redemptionMethod.userPays,
              Name,
              UnitOfMeasureCode,
              CurrencyCode,
              DecimalPrecision,
              Translations
            }
          }
          setRedemptionMethod(redemptionMethod);
        }
      } catch (error) {
        handleError({ error })
      }
    };
    getRedemptionMethodFromConfig();
  }, []); // eslint-disable-line

  useEffect(() => {
    const checkKycTier = () => {
      const { kycTierFunctionalRestrictions } = config;
      const redemptionMethodTier = redemptionMethod?.kycTier || 0;
      const redemptionAvailability =
        kycTierFunctionalRestrictions?.availability?.redemption || 0;
      const redemptionVisibility =
        kycTierFunctionalRestrictions?.visibility?.redemption || 0;
      const requiredTier = Math.max(
        redemptionMethodTier,
        Math.max(redemptionAvailability, redemptionVisibility)
      );

      const isUserTierSufficient =
        !isKycEnabled || isAdmin || currentKycTier >= requiredTier;

      if (!isUserTierSufficient) {
        history.replace(ROUTES.redemption);
      }
      setIsLoading(false);
    }
    if (redemptionMethod) {
      RestrictedMode ? setIsLoading(false) : checkKycTier();
    }
  }, [redemptionMethod, RestrictedMode]);

  return (
    <>
      {isLoading ? (
        <LoadingSpinner
          classes="mr-auto ml-auto"
          dataQa="loading-page"
        />
      ) : (
        <div className="flex flex-col gap-16 sm:gap-24">
          <Text dataQa="redemption-description">
              {getConfigTranslation(config?.redeemSettings?.methodSelectionDescription)}
          </Text>
          <Route path={ROUTES.redemptionForm}>
            <RedemptionDetailsForm
              redemptionMethod={redemptionMethod}
            />
          </Route>
        </div>
      )}
    </>
  );
};

export default RedeemDetails;
