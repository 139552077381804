import { PaymentMethod } from "Enums";
import { combineValidators, composeValidators } from "revalidate";
import { i18nextKeys } from "Lang/i18nextKeys";
import i18nextTranslateDynamically from "Lang/i18nextTranslateDynamically";
import {
  isFieldOneOf,
  isFieldRequired,
  isNumber,
  isObject,
  isRequiredArray,
  isRequiredBoolean,
  isString
} from "Utils/validators";
import customTermsArrayValidator from "./customTermsValidator";
import translationValidator from './translationValidator';

const paymentMethodValidator = (defaultLanguage) => (method) => combineValidators({
  type: composeValidators(
    isFieldRequired,
    isFieldOneOf([PaymentMethod.values.BankTransfer])('type')
  )('type'),
  customTerms: composeValidators(
    isRequiredArray("customTerms"),
    customTermsArrayValidator(defaultLanguage)
  )("customTerms")
})(method);

const paymentMethodArrayValidator = (defaultLanguage) => ({ field }, methods) => {
  if (methods.length) {
    return methods.map(paymentMethodValidator(defaultLanguage));
  } else {
    return i18nextTranslateDynamically(i18nextKeys.helperValidatorArrayEmpty, { field });
  }
};

const limitValidator = (defaultLanguage) => ({
  enabled: isRequiredBoolean("enabled"),
  "amount": isNumber("amount"),
  "notificationText": composeValidators(
    isObject(''),
    translationValidator(defaultLanguage)
  )('notificationText'),
  "emailAddress": isString("amount"),
  "emailSubject": composeValidators(
    isObject(''),
    translationValidator(defaultLanguage)
  )('emailSubject'),
  "emailBody": composeValidators(
    isObject(''),
    translationValidator(defaultLanguage)
  )('emailBody')
});

const checkoutConfigValidator = (defaultLanguage) => ({
  hideShop: isRequiredBoolean("hideShop"),
  limit: limitValidator(defaultLanguage),
  paymentMethods: composeValidators(
    isRequiredArray("paymentMethods"),
    paymentMethodArrayValidator(defaultLanguage)
  )("paymentMethods")
});

export default checkoutConfigValidator;
